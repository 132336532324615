import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String,
    param: String,
    extraParam: Object
  }

  connect() {
    if (this.selectTarget.id === '') {
      this.selectTarget.id = Math.random().toString(36)
    }
  }

  change(event) {
    const params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('target', this.selectTarget.id)
    for (const extraParamKey in this.extraParamValue) {
      params.append(`${extraParamKey}`, this.extraParamValue[extraParamKey])
    }

    get(`${this.urlValue}&${params}`, {
      responseKind: 'turbo-stream'
    })
  }
}
