import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['urlTemplateContainer']
  static values = { itemHtmlSelector: String }

  connect() {
    document.addEventListener('autocomplete.change', this.updateUrl.bind(this))
  }

  disconnect() {
    document.removeEventListener('autocomplete.change', this.updateUrl.bind(this))
  }

  urlTemplateContainerTargetConnected(target) {
    const url = target.dataset.urlBaseValue
    const selectedIdsWithoutIdsParams = this._selectedIdsForParams()

    target.dataset.autocompleteUrlValue = `${url}${selectedIdsWithoutIdsParams}`
  }

  urlTemplateContainerTargetDisconnected() {
    this.updateUrl()
  }

  updateUrl() {
    const selectedIdsWithoutIdsParams = this._selectedIdsForParams()

    document.querySelectorAll('[data-autocomplete-url-value]').forEach(function (element) {
      const url = element.dataset.urlBaseValue
      element.dataset.autocompleteUrlValue = `${url}${selectedIdsWithoutIdsParams}`
    })
  }

  _selectedIdsForParams() {
    const alreadyPickIds = document.querySelectorAll(this.itemHtmlSelectorValue)
    const ids = [...alreadyPickIds].map(i => i.value).filter(i => i)
    return '&without_ids[]=' + ids.join('&without_ids[]=')
  }
}
