import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { templateConverter } from '../lib/template_converter'
import { formUrlEncodedParameters } from "../lib/form_parameters_converter";

export default class extends Controller {
  static targets = [
    'checkbox',
    'checkboxList',
    'searchInput',
    'badgeTemplate',
    'selectResult',
    'statusTextToAdd',
    'statusTextAvailable'
  ]

  static values = {
    async: Object,
    max: Number
  }

  static classes = ['status', 'pointer']

  initialize() {
    this.checkedValue = 0
  }

  connect() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    if (this.hasAsyncValue) {
      get(`${this.asyncValue.url}`)
    }
  }

  checkboxListTargetConnected() {
    this.checkInputStatus()
  }

  toggle(event) {
    if (event.target.checked) {
      this.checkedValue += 1
      this.addBadge(event.currentTarget)
    } else {
      this.checkedValue -= 1
      this.removeBadge(event.currentTarget)
    }
  }

  uncheck(event) {
    const checkbox = document.getElementById(`checkbox-${event.currentTarget.dataset.id}`)
    const container = checkbox.closest('.select-badge-container')
    const statusContainer = container.querySelector('.select-list-element-status')
    checkbox.checked = false
    statusContainer.innerHTML = this.statusTextAvailableTarget.innerHTML
    statusContainer.classList.remove(...this.statusClasses)
    container.querySelector('label').classList.remove(this.pointerClass)
    event.currentTarget.parentElement.remove()
    this.checkedValue -= 1
    this.checkInputStatus()
  }

  addBadge(target) {
    const badgeInnerHTML = templateConverter(this.badgeTemplateTarget.innerHTML,
      {
        id: target.dataset.id,
        text: target.dataset.text,
        color: target.dataset.color,
        texthover: target.dataset.texthover
      })
    const container = target.closest('.select-badge-container')
    const statusContainer = container.querySelector('.select-list-element-status')
    this.selectResultTarget.insertAdjacentHTML('beforeend', badgeInnerHTML)
    container.querySelector('label').classList.add(this.pointerClass)
    statusContainer.innerHTML = this.statusTextToAddTarget.innerHTML
    statusContainer.classList.add(...this.statusClasses)
    this.checkInputStatus()
  }

  disableAllUncheckedInputs() {
    this.checkboxTargets.forEach(input => {
      if (input.checked === false) {
        input.disabled = true
      }
    })
  }

  enableAllInputs() {
    this.checkboxTargets.forEach(input => {
      input.disabled = false
    })
  }

  checkInputStatus() {
    if (this.maxValueReached()) {
      this.disableAllUncheckedInputs()
    } else {
      this.enableAllInputs()
    }
  }

  removeBadge(target) {
    document.getElementById(`select-badge-${target.dataset.id}`).remove()
  }

  filterAsync(event) {
    let urlEncodedParams = ''
    let needToQuery = false
    if (event.currentTarget.value.length < this.asyncValue.min_char) {
      if (this.isInitialCollection() === false) {
        urlEncodedParams = formUrlEncodedParameters(event.currentTarget.form, true)
        needToQuery = true
      }
    } else {
      urlEncodedParams = formUrlEncodedParameters(event.currentTarget.form)
      needToQuery = true
    }
    if (needToQuery === true) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout((urlEncodedParams) => {
        get(`${this.asyncValue.url}${urlEncodedParams}`)
      }, 300, urlEncodedParams)
    }
  }

  filterSync(event) {
    Array.from(this.checkboxListTarget.children).forEach((div) => {
      const a = div.getElementsByTagName('p')[0]
      const txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(event.currentTarget.value.toUpperCase()) > -1) {
        div.style.display = ''
      } else {
        div.style.display = 'none'
      }
    })
  }

  maxValueReached() {
    if (this.maxValue === 0) {
      return false
    } else {
      return this.maxValue <= this.checkedValue
    }
  }

  filter(event) {
    if (this.hasAsyncValue) {
      this.filterAsync(event)
    } else {
      this.filterSync(event)
    }
  }

  isInitialCollection() {
    return this.checkboxListTarget.parentNode.dataset.initialCollection === 'true'
  }
}
